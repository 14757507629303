import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Button from 'components/ui-components-v2/Button';
import SearchField from 'components/ui-components/SearchField';
import SidebarListItem from './list-item';
import '../styles/list.scss';

const getShowItem = (list, key) => {
    return list.filter((item) => !!item[key]).length > 0;
};

/**
 * SidebarList
 * The list of all the items displayed in the sidebar
 */
const SidebarList = ({
    listTitle,
    list,
    activeItem,
    onSelect,
    onClickMainButton,
    mainButtonCopy,
    mainButtonIcon,
    onClickSubButton,
    subButtonCopy,
    subButtonIcon,
    classes,
    docked
}) => {
    const [showStatus, setShowStatus] = useState(getShowItem(list, 'status'));
    const [showType, setShowType] = useState(getShowItem(list, 'typeIcon'));
    const [showSubNav, setShowSubNav] = useState(getShowItem(list, 'items'));
    const [inactiveSubItems, setInactiveSubItems] = useState([]);
    const [filteredList, setFilteredList] = useState(list);
    const [usedQuery, setUsedQuery] = useState('');

    const onToggleSubItems = (item, toggleType) => {
        if (item.id !== activeItem && toggleType === 'open') {
            const newInactiveSubItems = [...inactiveSubItems];
            newInactiveSubItems.push(item.id);
            setInactiveSubItems(newInactiveSubItems);
        }
        if (item.id !== activeItem && toggleType === 'close') {
            if (inactiveSubItems.includes(item.id)) {
                let newInactiveSubItems = [...inactiveSubItems];
                newInactiveSubItems = newInactiveSubItems.filter((i) => i !== item.id);
                setInactiveSubItems(newInactiveSubItems);
            }
        }
    };

    useEffect(() => {
        setShowStatus(getShowItem(list, 'status'));
        setShowType(getShowItem(list, 'typeIcon'));
        setShowSubNav(getShowItem(list, 'items'));
        doSearch(list, usedQuery);
    }, [list]);

    /**
     * Save the query in state and execute the search
     * @param {*} e
     * @param {string} query
     */
    const search = (e, query) => {
        setUsedQuery(query);
        doSearch(list, query);
    };

    /**
     * Filter the list based on a query
     * @param {array} list
     * @param {string} query
     */
    const doSearch = (list, query) => {
        if (!query || query.length === 0) {
            setFilteredList(list);
        } else {
            const filteredList = list.filter((item) => {
                const found = false;
                let searchIsValidRegEx = true;
                try {
                    new RegExp(query.toLowerCase());
                } catch (e) {
                    searchIsValidRegEx = false;
                }

                if (searchIsValidRegEx) {
                    if (item.title && item.title.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                        return true;
                    }
                }
                return found;
            });
            setFilteredList(filteredList);
        }
    };

    return (
        <div
            className={classNames('sidebar-list', classes?.root, {
                'sidebar-list--big': showStatus,
                'sidebar-list--main-button': onClickMainButton,
                'sidebar-list--docked': docked
            })}>
            {listTitle && <div className="sidebar-list__header">{listTitle}</div>}
            {list && list.length > 10 && (
                <div className={classNames('sidebar-list__search', classes?.search)}>
                    <SearchField onChange={search} />
                </div>
            )}
            {filteredList.map((item) => {
                const parent = (item.items && item.items.length > 0) || onClickSubButton;
                const itemActive = item.id === activeItem;
                const subItemActive = item.items && item.items.filter((i) => i.id === activeItem).length > 0;
                const inactiveSubItemOpen = inactiveSubItems.includes(item.id);
                const subItemsOpen = parent && (itemActive || subItemActive || inactiveSubItemOpen);

                return (
                    <React.Fragment key={item.id}>
                        <SidebarListItem
                            item={item}
                            active={itemActive}
                            showStatus={showStatus}
                            showType={showType}
                            showSubNav={showSubNav}
                            onSelect={onSelect}
                            parent={parent}
                            subItemsOpen={subItemsOpen}
                            activeItem={activeItem}
                            onToggleSubItems={(item, toggleType) => onToggleSubItems(item, toggleType)}
                        />
                        {subItemsOpen && (
                            <div className="sidebar-list__subnav">
                                {item.items &&
                                    item.items.map((sub, index) => (
                                        <SidebarListItem
                                            key={sub.id ? sub.id : index}
                                            item={sub}
                                            active={sub.id === activeItem}
                                            showType={getShowItem(item.items, 'typeIcon')}
                                            showStatus={getShowItem(item.items, 'status')}
                                            onSelect={onSelect}
                                            subItem
                                        />
                                    ))}

                                {onClickSubButton && (
                                    <div className="sidebar-list__subnav__button" onClick={() => onClickSubButton(item)}>
                                        {subButtonIcon}
                                        <div className="sidebar-list__subnav__button__copy">{subButtonCopy}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </React.Fragment>
                );
            })}

            {onClickMainButton && (
                <div className="sidebar-list__button">
                    <Button variant="contained" onClick={onClickMainButton} endIcon={mainButtonIcon}>
                        {mainButtonCopy}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default SidebarList;
