import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';

import '../styles/view.scss';

interface Props {
    children?: React.ReactNode;
}

const TabbarView = ({ children }: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    return (
        <div className="tabbar-view">
            <Button onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)} endIcon={<Icon>visibility</Icon>}>
                {Translation.get('actions.view', 'common')}
            </Button>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                classes={{
                    paper: 'tabbar-view__content'
                }}>
                <div className="tabbar-view__items" onClick={() => setAnchorEl(null)}>
                    {children}
                </div>
            </Popover>
        </div>
    );
};

export default TabbarView;
