import React from 'react';
import GenericFilter from 'components/ui-base/GenericFilter';

interface Props {
    filterSetup: object[];
    filters?: object;
    onMutation: (newFilters) => void;
}

const TabbarFilter = ({ filterSetup, filters, onMutation }: Props) => {
    return (
        <GenericFilter
            standAlone
            buttonVariant="text"
            ignoreInFiltersCount={['searchTerm']}
            filterSetup={filterSetup}
            filters={filters}
            onMutation={onMutation}
        />
    );
};

export default TabbarFilter;
