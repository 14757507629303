import React from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Chip from 'components/ui-components-v2/Chip';
import '../styles/tabs.scss';
import { Tab } from '../types/tab-bar-tab.type';

interface Props {
    tabs: Tab[];
    outlinedIcons?: boolean;
    className?: string;
    onClick: (item: Tab) => void;
    onDelete?: (level?: string) => void;
}

const TabbarTabs = ({ tabs, outlinedIcons, className, onClick, onDelete }: Props) => {
    return (
        <div className={classNames('tabbar-tabs', className)} data-tour-selector="tabbar">
            {tabs.map(
                (item, i) =>
                    ((item.visible !== undefined && item.visible) || item.visible === undefined) && (
                        <Tooltip key={'item' + i} title={item.toolTip}>
                            <div>
                                {item.type && item.type === 'custom' && item.component ? (
                                    item.component
                                ) : (
                                    <div
                                        className={classNames('tabbar-tabs__tab', {
                                            'tabbar-tabs__tab--active': item.active,
                                            'tabbar-tabs__tab--disabled': item.isDisabled
                                        })}
                                        data-tour-selector={item.tourSelector ? item.tourSelector : 'tab-' + item.title}
                                        onClick={() => onClick(item)}>
                                        {item.icon && <Icon className="tabbar-tabs__icon">{item.icon}</Icon>}
                                        <span className="tabbar-tabs__title">{item.title}</span>
                                        {item.chipAmount ? (
                                            <Chip
                                                size="small"
                                                className="tabbar-tabs__chip"
                                                label={item.chipAmount + ' selected'}
                                                onDelete={() => (onDelete ? onDelete(item.level) : undefined)}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                )}
                            </div>
                        </Tooltip>
                    )
            )}
        </div>
    );
};

export default TabbarTabs;
