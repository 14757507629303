import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Status from 'components/ui-components/Status';
import Typography from 'components/ui-components-v2/Typography';
import '../styles/list-item.scss';

/**
 * SidebarListItem
 * This is an individual listitem in the sidebar
 * @param {*} param0
 */
const SidebarListItem = ({ item, active, activeItem, showStatus, showType, showSubNav, parent, subItemsOpen, subItem, onSelect, onToggleSubItems }) => {
    const location = useLocation();

    /**
     * Store the selected item in session storage so it can be openend upon return to the instance of this component.
     * The instance is recognized by it's path within CD.
     */
    const rememberSelect = () => {
        const sidebarOpen = sessionStorage.getItem('sidebarOpen');
        let newSidebarOpen;
        if (sidebarOpen) {
            newSidebarOpen = {
                ...JSON.parse(sidebarOpen),
                [location.pathname]: item.id
            };
        } else {
            newSidebarOpen = { [location.pathname]: item.id };
        }
        sessionStorage.setItem('sidebarOpen', JSON.stringify(newSidebarOpen));
    };

    const toggleSubItems = (e, item, toggleType) => {
        e.stopPropagation();
        onToggleSubItems(item, toggleType);
    };

    return item ? (
        <div
            className={classNames('sidebar-list-item', {
                'sidebar-list-item--active': active,
                'sidebar-list-item--bare': !showStatus && !showType,
                'sidebar-list-item--parent': parent,
                'sidebar-list-item--open': subItemsOpen,
                'sidebar-list-item--sub': subItem,
                'sidebar-list-item--button': item.type === 'button'
            })}
            onClick={() => {
                rememberSelect();
                onSelect(item.id);
            }}
            title={item.title}>
            {showStatus && (
                <div
                    className={classNames('sidebar-list-item__status', {
                        'sidebar-list-item__status--active': active,
                        'sidebar-list-item__status--noborder': !showType && !showSubNav
                    })}>
                    <Status status={item.status} primary={active} />
                </div>
            )}
            {showSubNav && (
                <div
                    className={classNames('sidebar-list-item__subnav', {
                        'sidebar-list-item__subnav--active': active
                    })}>
                    {parent ? (
                        subItemsOpen ? (
                            <IconButton disabled={item.id === activeItem} size="small" onClick={(e) => toggleSubItems(e, item, 'close')}>
                                <Icon>arrow_drop_down</Icon>
                            </IconButton>
                        ) : (
                            <IconButton size="small" onClick={(e) => toggleSubItems(e, item, 'open')}>
                                <Icon>arrow_right</Icon>
                            </IconButton>
                        )
                    ) : (
                        <span>&nbsp;</span>
                    )}
                </div>
            )}

            {showType && (
                <div className={classNames('sidebar-list-item__type', { 'sidebar-list-item__type--active': active })}>{!!item.typeIcon && item.typeIcon}</div>
            )}
            {item.type === 'button' && <div>{item.buttonText}</div>}
            <div className="sidebar-list-item__content">
                <div className="sidebar-list-item__content__text">
                    <Typography variant="h5" component="div">
                        {item.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="div">
                        {item.subTitle}
                    </Typography>
                </div>
            </div>
            {item.reviewStatus && (
                <div className={classNames('sidebar-list-item__review-status', 'sidebar-list-item__review-status--' + item.reviewStatus)}> &bull;</div>
            )}
        </div>
    ) : null;
};

SidebarListItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        status: PropTypes.string,
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        typeIcon: PropTypes.object,
        items: PropTypes.array,
        parent: PropTypes.bool,
        subItemsOpen: PropTypes.bool,
        subItem: PropTypes.bool,
        showSubNav: PropTypes.bool
    }).isRequired,
    active: PropTypes.bool,
    showStatus: PropTypes.bool,
    showType: PropTypes.bool,
    onSelect: PropTypes.func
};

SidebarListItem.defaultProps = {
    onSelect: () => {}
};

export default SidebarListItem;
