import React from 'react';
import ButtonBase from '@mui/material/ButtonBase/ButtonBase';
import Icon from 'components/ui-components-v2/Icon';
import Radio from 'components/ui-components-v2/Radio';

import '../styles/view-item.scss';

interface Props {
    checked?: boolean;
    icon: string;
    label: string;
    onClick: () => void;
}

const TabbarViewItem = ({ checked, icon, label, onClick }: Props) => {
    return (
        <ButtonBase className="tabbar-view-item" onClick={onClick}>
            <Radio size="small" checked={checked} />
            <Icon fontSize="small" className="tabbar-view-item__icon">
                {icon}
            </Icon>
            <div className="tabbar-view-item__label">{label}</div>
        </ButtonBase>
    );
};

export default TabbarViewItem;
