import React, { ReactNode } from 'react';
import classNames from 'classnames';
import TabbarTabs from './tabs';
import { Tab } from '../types/tab-bar-tab.type';

import '../styles/main.scss';

interface Props {
    tabs: Tab[];
    className?: string;
    style?: object;
    outlinedIcons?: boolean;
    classes?: {
        root?: string;
        container?: string;
        tabs?: string;
        left?: string;
        right?: string;
    };
    left?: ReactNode | string;
    children?: ReactNode | string;
    onClick: (item: Tab) => void;
    onDelete?: (level?: string) => void;
}

/**
 * A general tab bar used for navigating pages and menu's.
 */
const Tabbar = ({ tabs = [], left, children, classes, style = {}, outlinedIcons, className, onClick, onDelete }: Props) => {
    return (
        <div className={classNames('tabbar', className, classes?.root)} style={style}>
            <div className={classNames('tabbar__container', classes?.container)}>
                {left && <div className={classNames('tabbar__left', classes?.left)}>{left}</div>}
                <TabbarTabs tabs={tabs} className={classes?.tabs} outlinedIcons={outlinedIcons} onClick={onClick} onDelete={onDelete} />
                {children && <div className={classNames('tabbar__right-buttons', classes?.right)}>{children}</div>}
            </div>
        </div>
    );
};

export default Tabbar;
