import Tabbar from './components';
import TabbarTabs from './components/tabs';
import TabbarFilter from './components/filter';
import TabbarSearch from './components/search';
import TabbarView from './components/view';
import TabbarViewItem from './components/view-item';
import { Tab } from './types/tab-bar-tab.type';

export default Tabbar;
export { Tabbar, TabbarTabs, TabbarFilter, TabbarSearch, TabbarView, TabbarViewItem };
export type { Tab };
