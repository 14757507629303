import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import SidebarList from './list';
import '../styles/main.scss';

/**
 * Sidebar
 * This displays a sidebar with different options which are selectable
 */
const Sidebar = ({
    list = [],
    listTitle,
    activeItem,
    showSingle = false,
    // eslint-disable-next-line no-unused-vars
    onSelect = (id) => {},
    children,
    freshStart, // Always start with the first item from the list.
    onClickMainButton,
    mainButtonCopy,
    mainButtonIcon,
    onClickSubButton,
    subButtonCopy,
    subButtonIcon,
    className,
    classes,
    docked = false
}) => {
    const location = useLocation();

    // Initialize the sidebar
    useEffect(() => {
        // Find previously selected item in the sidebar from session storage
        const sidebarOpen = sessionStorage.getItem('sidebarOpen');
        let found = false;
        if (sidebarOpen && !freshStart) {
            // Find the select item in the list
            const checkSidebarOpen = JSON.parse(sidebarOpen);
            if (checkSidebarOpen[location.pathname]) {
                const openId = checkSidebarOpen[location.pathname];
                list.forEach((x) => {
                    if (x.id === openId) {
                        onSelect(x.id);
                        found = true;
                    }
                    // Subitem in the list
                    else if (x.items) {
                        x.items.forEach((y) => {
                            if (y.id === openId) {
                                onSelect(y.id);
                                found = true;
                            }
                        });
                    }
                });
            }
        }
        if (!found && list && list.length) {
            onSelect(list[0].id);
        }
    }, []);

    const minimumLength = showSingle ? 0 : 1;

    return (
        <div className={classNames('sidebar', className, classes?.root, { 'sidebar--docked': docked })}>
            {list && list.length > minimumLength && (
                <SidebarList
                    list={list}
                    listTitle={listTitle}
                    activeItem={activeItem}
                    onSelect={onSelect}
                    onClickMainButton={onClickMainButton}
                    mainButtonCopy={mainButtonCopy}
                    mainButtonIcon={mainButtonIcon}
                    onClickSubButton={onClickSubButton}
                    subButtonCopy={subButtonCopy}
                    subButtonIcon={subButtonIcon}
                    classes={{ root: classes?.list, search: classes?.search }}
                    docked={docked}
                />
            )}
            <div className={classNames('sidebar__detail', classes?.detail, { 'sidebar__detail--docked': docked })}>{children}</div>
        </div>
    );
};

// Default proptypes
Sidebar.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            status: PropTypes.string,
            title: PropTypes.string.isRequired,
            subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            typeIcon: PropTypes.object,
            items: PropTypes.array
        })
    ),
    listTitle: PropTypes.string,
    activeItem: PropTypes.string,
    freshStart: PropTypes.bool,
    onSelect: PropTypes.func,
    showSingle: PropTypes.bool, // Whether or not the sidebar should be visible with only one item (default false)
    onClickMainButton: PropTypes.func, // The button on the bottom of the entire sidebar
    mainButtonCopy: PropTypes.string,
    mainButtonIcon: PropTypes.any,
    onClickSubButton: PropTypes.func, // The button on the bottom of an item with subitems
    subButtonCopy: PropTypes.string,
    subButtonIcon: PropTypes.any,
    className: PropTypes.string,
    classes: PropTypes.object,
    docked: PropTypes.bool
};

export default Sidebar;
